<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <div class="card-header">
    <span class="title" style="font-size: 22px; line-height: 45px; vertical-align: middle; position: relative;">Master
      RI Order Report</span>
      <div style="float: right; margin: 0 10px">
    <button class="btn btn-primary-soft"  (click)="load()">
      <i class="fa fa-refresh" aria-hidden="true"></i>
      Refresh
    </button>
    <button class="btn btn-success-soft" (click)="export()">
      Download
      </button>
    </div>
    
    <!-- <button class="btn btn-success-soft" style="float: right; margin: 0 10px"
      *ngIf="(!fetching && !nextToken && this.summary.length > 0) || (!fetching && selectedMasterAccount)"
      (click)="export()"><i class="fa fa-download" aria-hidden="true"></i>
      Download</button> -->
  </div>
  <div class="card-body">
    <!-- <div style="display: flex;
   
    position: relative;
   ">
        <label style="margin-right: 10px;">Master Account List</label>
        <select name="" id="masterAccountList" class="selectpricket"
          data-live-search="true" (change)="load()">
          <option [value]="master" *ngFor="let master of masterAccounts">{{master}}</option>
        </select>
    </div> -->


    <table class="table table-docs table-hover" id="master_accounts_table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">
            {{ h.name }}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,rimodels)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody [ngStyle]="{'display': rimodels.length > 0 ? 'table-row-group' : 'none'}">
        <ng-container *ngFor="let i of rimodels; let j = index">
          <tr *ngIf="notifier.filterTableColums(headers ,i)">
            <td>{{j + 1}}</td>
            <td *ngFor="let h of headers">
              <span *ngIf="!h.click">{{h.alter ? h.alter(i, h.id) : i[h.id]}}</span>
              <span class="btn" *ngIf="h.click" (click)="callFunction(h.click, i)">{{h.alter ? h.alter(i, h.id) :
                i[h.id]}}</span>
            </td>
          </tr>
          <!-- <tr *ngIf="isLoadingMoreData">
            <td colspan="100%" style="text-align: center;">
              <img src="../assets/img/loading_2.svg" alt="loading data" width="25" style="margin: 10px;" />
            </td>
          </tr> -->
        </ng-container>
      </tbody>
      <tbody *ngIf="rimodels.length == 0 && !fetching">
        <tr>
          <td [attr.colspan]=" (headers ).length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="text-align: center;" *ngIf="fetching">
      <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px" />
    </div>

  </div>
  <!-- <div class="card-body" *ngIf="selectedMasterAccount">
    <div style="padding: 5px; display: flex; justify-content: flex-start; align-items: center; gap: 6px;">
      <span style="color: blue; cursor: pointer;" (click)="selectedMasterAccount = null">Master Accounts</span>
      <span>></span>
      <span>{{selectedMasterAccount.masterAccountId}}</span>
    </div>
     <div style="margin: 10px 0px; padding: 10px; border-radius: 10px; box-shadow: 1px 1px 3px 1px grey;">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th *ngFor="let h_ of CBSPortal ? CBSSummaryHeader : summaryHeader">{{ h_.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td *ngFor="let h_ of CBSPortal ? CBSSummaryHeader : summaryHeader">
              <span *ngIf="!h_.click" style="font-weight: bold;"
                [innerHTML]="h_.alter ? h_.alter(sumAccount(), h_.id) : sumAccount()[h_.id]"> </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div> 
     <table class="table table-docs table-hover" id="linked_data_table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of lidAccountsHeader">
            {{ h.name }}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,selectedMasterAccount.lids)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody [ngStyle]="{'display': selectedMasterAccount.lids.length > 0 ? 'table-row-group' : 'none'}">
        <ng-container *ngFor="let i of selectedMasterAccount.lids; let j = index">
          <tr *ngIf="notifier.filterTableColums(lidAccountsHeader ,i)">
            <td>{{j + 1}}</td>
            <td *ngFor="let h of lidAccountsHeader">
              <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"></span>
              <span class="btn" *ngIf="h.click" (click)="callFunction(h.click, i)" [innerHTML]="h.alter ? h.alter(i, h.id) :
                i[h.id]"></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tbody *ngIf="selectedMasterAccount.lids.length == 0 && !fetching">
        <tr>
          <td [attr.colspan]="(lidAccountsHeader ).length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table> 
    <div style="text-align: center;" *ngIf="fetching">
      <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px" />
    </div>

  </div> -->
</div>