import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { regions } from '../../../../dash/dash.component';

declare let $: any;
declare let flatpickr: any;

@Component({
  selector: 'elastic-enableri-update',
  templateUrl: './elasticupdate.enableri.component.html',
  styleUrls: ['./elasticupdate.enableri.component.css'],
})
export class ElasticUpdateComponent implements OnInit, OnDestroy {
  additionalContacts: string[] = [''];
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  regions: any = regions;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  dateRange: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input() ri: any;
  @Input('action') action: any;
  instanceType: any = [];
  nodeType: any = [];
  engineType: any = [];
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.ri = { ...this.ri };
    $('#regionsList').selectpicker();
    setTimeout(() => {
      $('#regionsList').selectpicker('refresh');
    }, 1000);
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
console.log("Ri in oninit",this.ri)
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    setTimeout(() => {
      this.dateRange = this.ri.startDate + ' to ' + this.ri.endDate;
      flatpickr('.datetimepicker', {
        dateFormat: 'Y-m-d',
        mode: 'range',
        defaultDate: [this.ri.startDate, this.ri.endDate],
        showMonths: 2,
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          if (!dateStr.includes('to')) return;
          this.dateRange = dateStr;
        },
      });
    }, 500);
  }

  load() {
    this.fetchInstanceTypes();
    // setTimeout(() => {
    //   $('#deploymentType').selectpicker('refresh');
    //   $('#billingTypes').selectpicker('refresh');

    // }, 10);
  }

  async fetchRICost() {
    let instanceType: string = $('#instanceTypes').val();
    let engines: string = $('#engines').val();
    let region: string = $('#regionsList').val();
    let bt: string = $('#billingTypes').val();
    let deploymentType: String = $('#deploymentType').val();
    if (
      this.ri.riType == 'OnDemand' ||
      !engines ||
      !instanceType ||
      !deploymentType ||
      !region ||
      !bt
    ) {
      return;
    }
    this.notifier.loading(true);
    let data = {
      action: 'fetchCacheRiPrice',
      instanceType: instanceType,
      engineType: engines,
      region: region,
      nodeType: deploymentType,
      riType: bt

    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL =
      'https://dev.api.swayam.cloud/v3/admin/billing/elastic-cache-enable';
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      if(result.ppu.length > 0){
        this.ri.riHrCost = result.ppu;

      }
      else{
        this.ri.riHrCost = result.ppu;
        this.notifier.alert('Error', '', "0 available nodes", 'error', 5000);
      }
      console.log('ondemand result',result);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async fetchInstanceTypes() {
    this.notifier.loading(true);
    let data = { action: 'fetchCacheDetails' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL =
      'https://dev.api.swayam.cloud/v3/admin/billing/elastic-cache-enable';

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      console.log('instance types result',result);
      this.instanceType = result.cacheDetails.instanceType;
      console.log('instanceType',this.instanceType)

      this.engineType = result.cacheDetails.engineType;
      console.log('engineType',this.engineType)

      this.nodeType = result.cacheDetails.nodeType;
      console.log('nodeType',this.nodeType)

      if (this.action.trim() == 'save') {
        if (!this.ri.instanceType) {
          this.ri.instanceType = 'NA';
        }
        if (!this.ri.engineType) {
          this.engineType[0];
        }
      }
      setTimeout(() => {
        $('#instanceTypes').selectpicker('refresh');
        $('#engines').selectpicker('refresh');
        $('#deploymentType').selectpicker('refresh');
        $('#billingTypes').selectpicker('refresh');
  
        
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  showInvalidFieldNotification() {
    if (!($('#instanceTypes').val())) {
      this.notifier.alert('Error', '', 'Instance Type is required', 'error', 5000);
      return;
    }
    if (!($('#engines').val())) {
      this.notifier.alert('Error', '', 'Cache engine is required', 'error', 5000);
      return;
    }

    if (!($('#regionsList').val())) {
      this.notifier.alert('Error', '', 'Region is required', 'error', 5000);
      return;
    }
    if (!($('#deploymentType').val())) {
      this.notifier.alert('Error', '', 'Node Type is required', 'error', 5000);
      return;
    }
    if (!this.ri.quantity) {
      this.notifier.alert('Error', '', 'Quantity is required', 'error', 5000);
      return;
    }

    if (!this.ri.riType) {
      this.notifier.alert('Error', '', 'RI Type is required', 'error', 5000);
      return;
    }

    if (!this.ri.discount) {
      this.notifier.alert('Error', '', 'Discount is required', 'error', 5000);
      return;
    }
  }

  isFormValid() {
    return !!this.ri.regionn && !!this.ri.usageType && !!this.ri.discountPercentage && !!this.ri.unit && !!this.ri.ppu;
  }

  async updateEntry() {
console.log('daterange',this.dateRange);
    if(this.dateRange == '' || this.dateRange == undefined || this.dateRange == ' to '){
      this.notifier.alert('Error', '', 'Please select date range', 'error', 500);
      return;
    }


    let sDate = $('.datetimepicker').val().split(' to ');
    let start_date = sDate[0];
    let end_date = sDate[1];

    if (!this.isFormValid() && this.action !== 'update') {
      this.showInvalidFieldNotification();
      return;
    }


    if (this.action.trim() == 'save') {
      this.close({
        accountId: this.accountId,
        action: 'add',
        cfrcModels: [
          {
            instanceType: $('#instanceTypes').val(),
            engineType: $('#engines').val(),
            nodeType: $('#deploymentType').val(),
            region: $('#regionsList').val(),
            quantity: this.ri.quantity.toString(),
            riType: $('#billingTypes').val(),
            discount: this.ri.discount,
            startDate: start_date,
            endDate: end_date,
            riHrCost: this.ri.riType != 'OnDemand' ? this.ri.riHrCost : '0',
          },

        ],
      });
      return;
    }
    this.notifier.loading(true);
    let data: any = {
      accountId: this.accountId,
      action: 'update',
  cfrcModels: [
    {
      uuid: this.ri.uuid,
      riType: $('#billingTypes').val() || 'NA',
      riHrCost: this.ri.riType != 'OnDemand'
      ? this.ri.riHrCost >= 0
        ? this.ri.riHrCost
        : '0'
      : undefined,
      discount: this.ri.discount || 'NA',
    }
  ]
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL =
      'https://dev.api.swayam.cloud/v3/admin/billing/elastic-cache-enable';

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.close(true);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }

  close(dataAction: any = false) {
    if (dataAction) {
      this.hideModel.emit(dataAction);
    } else {
      this.hideModel.emit(false);
    }
  }
}
