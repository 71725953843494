<div class="alarm_screen" style="box-shadow:  1px 3px 1px #80808024 ">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform:capitalize">{{action == 'update' ? 'Update' : 'Add'}} Elastic Cache</h4>
    </div>
    <div class="alarm_body" style=" padding: 1px 25px 5px 25px">
      <div class="row">
        <label>Cache Instance Type</label>
        <select class="form-control" id="instanceTypes" (change)="fetchRICost()" [(ngModel)]="ri.instanceType"
          data-live-search="true" [disabled]="action == 'update'">
          <option *ngFor="let type of instanceType" value="{{type}}" >{{type}}</option>
        </select>
      </div>
      <div class="row">
        <label>Cache Engine</label>
        <select class="form-control" id="engines" (change)="fetchRICost()" [(ngModel)]="ri.engineType"
          data-live-search="true" [disabled]="action == 'update'">
          <option *ngFor="let platform of engineType" value="{{platform}}">{{platform}}</option>
        </select>
      </div>
      <div class="row">
        <label>Region</label>
        <select class="form-control" id="regionsList" (change)="fetchRICost()" [(ngModel)]="ri.region"
          data-live-search="true" [disabled]="action == 'update'">
          <option *ngFor="let region of regions" value="{{region.id}}">{{region.name}}</option>
        </select>
      </div>
      <div class="row">
        <label>Node Type</label>
        <select class="form-control" id="deploymentType" (change)="fetchRICost()" [(ngModel)]="ri.nodeType"
          data-live-search="true" [disabled]="action == 'update'">
          <option *ngFor="let node of nodeType" value="{{node}}">{{node}}</option>
        </select>
      </div>
      <div class="row">
        <label>Quantity</label>
        <input type="number" class="form-control" [(ngModel)]="ri.quantity" [disabled]="action == 'update'">
      </div>
      <div class="row">
        <label>RI Type</label>
        <select class="form-control" id="billingTypes" [(ngModel)]="ri.riType" (change)="fetchRICost()"
          data-live-search="true">
          <option value="OnDemand">OnDemand</option>

          <option value="RI 1yr NoUpfront">RI 1yr NoUpfront</option>
          <option value="RI 1yr AllUpfront">RI 1yr AllUpfront</option>
          <option value="RI 1yr PartialUpfront">RI 1yr PartialUpfront</option>
          <option value="RI 3yr AllUpfront">RI 3yr AllUpfront</option>
          <option value="RI 3yr PartialUpfront">RI 3yr PartialUpfront</option>

          <option value="ap-northeast-3">RI 1yr NoUpfront</option>

        </select>
      </div>
      <div class="row" [ngStyle]="{display: ri.riType == 'OnDemand' ? 'none': 'block'}">
        <label>RI hourly cost</label>
        <input type="text" class="form-control" [(ngModel)]="ri.riHrCost" readonly>
      </div>
      <div class="row">
        <label>Discount percentage:</label>
        <input type="text" class="form-control" [(ngModel)]="ri.discount">
      </div>
      <div class="row">
        <label>Period:</label>
        <input class="datetimepicker" type="text" [(ngModel)]="dateRange" [disabled]="action == 'update'"/>
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="updateEntry()" [disabled]="ri.riHrCost <= 0 && ri.riType !== 'OnDemand'"
            style="background-color: #5864ff; color: white; text-transform:capitalize; margin-right:20px">
            {{action == 'add' ? 'Add' : action}}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>