<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform: capitalize">
        {{ action == "update" ? "update" : "add" }} MRR
      </h4>
    </div>
    <div class="alarm_body" style="padding: 1px 25px 5px 25px;max-height: 600px; overflow-y: auto;">
      <div class="row" *ngIf="action !== 'update'">
        <div class="flex-between p-0">
          <label>Linked Account List</label>
          <div class="flex-start filters">
            <div class="flex-start">
              <input type="checkbox" id="select_all_accounts" (change)="selectAllchilds($event)" />
              <label for="select_all_accounts">Select All</label>
            </div>
          </div>
        </div>

        <select class="form-control" id="linkedAccounts" [(ngModel)]="childs" (change)="onAccountChange($event)"
          data-live-search="true" multiple required>
          <option *ngFor="let master of linkedAccounts['list']" [value]="master.accountId">
            {{ master.accountId }} ({{master.accountName}})
          </option>
        </select>

      </div>


      <div class="row">
        <div class="flex-between p-0">
          <label>Service Name</label>
          <div class="p-0 flex-start" *ngIf="action !== 'update'">
            <input type="checkbox" name="" (click)="selectAllOpt()" id="service_name" />
            <label for="service_name">Select All</label>
          </div>
        </div>
        <div class="p-0">
          <select *ngIf="action != 'update'" id="serviceNameId" class="form-control selectpicker"
            data-live-search="true" [disabled]="action == 'update'" multiple (change)="onServiceNameChange($event)">
            <option value="" disabled>--Select the service name--</option>
            <option *ngFor="let name of serviceNamelist" [value]="name"
              [attr.disabled]="isServiceDisabled(name) ? true : null">
              {{ name }}
            </option>
          </select>

          <select id="serviceNameId" class="form-control" *ngIf="action == 'update'" [disabled]="action == 'update'">
            <option value="{{ mrr.serviceName }}"> {{ mrr.serviceName }}</option>
          </select>

        </div>

      </div>


      <div class="row" *ngIf="action !== 'update'" style="padding:0;">

        <label>MRR Discount</label>
        <input type="number" class="form-control" [(ngModel)]="mrr.mrrDiscount" />
      </div>
      <div class="row" *ngIf="action == 'update'">
        <!-- && mrr.serviceName !== 'Amazon Elastic Compute Cloud' -->

        <div *ngIf="mrr.serviceName == 'Amazon Elastic Compute Cloud' && selectedClientName === 'NHA'">
          <div class="font-weight-bold mt-3 d-flex justify-content-between">
            <div>
              EC2 Discount
            </div>
            <div>
              <span class="view-details" style="cursor: pointer; color: blue;"
                (click)="openPopup('Amazon Elastic Compute Cloud')">View Details</span>
            </div>
          </div>
          <!-- data-bs-toggle="modal"
          data-bs-target="#expandmodel"  -->
          <div class="border p-3 mb-3">

            <div class="form-group row">
              <label>Others </label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.others" />
            </div>
            <div class="form-group row">
              <label>Instance</label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.instance" />
            </div>
            <div class="form-group row">
              <label>EBS</label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.ebs" />
            </div>
          </div>
        </div>
        <div *ngIf="(mrr.serviceName == 'Amazon Elastic Compute Cloud') && selectedClientName !== 'NHA'">
          <div class="font-weight-bold mt-3 d-flex justify-content-between">
            <div>
              EC2 Discount
            </div>
            <div>
              <span class="view-details" style="cursor: pointer; color: blue;"
                (click)="openPopup('Amazon Elastic Compute Cloud')">View Details</span>
            </div>
          </div>
          <div class="border p-3 mb-3">

            <div class="form-group row">
              <label>OD </label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.od" />
            </div>
            <div class="form-group row">
              <label>Spot</label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.spot" />
            </div>
          </div>
        </div>
        <div *ngIf="(mrr.serviceName == 'Amazon Relational Database Service') && selectedClientName !== 'NHA'">
          <div class="font-weight-bold mt-3 d-flex justify-content-between">
            <div>
              RDS OD Discount
            </div>
            <div>
              <!-- data-bs-toggle="modal"
                data-bs-target="#expandmodel" -->
              <span class="view-details" style="cursor: pointer; color: blue;"
                (click)="openPopup('Amazon Relational Database Service')">View Details</span>
            </div>
          </div>
          <div class="row">
            <input type="number" class="form-control" [(ngModel)]="mrrDatasub.od" />
          </div>
        </div>
        <div *ngIf="(showS3DiscountFields || isAllSelect) && selectedClientName !== 'NHA'">
          <div class="font-weight-bold mt-3 d-flex justify-content-between">
            <div>
              S3 Discount
            </div>
            <div>
              <span class="view-details" style="cursor: pointer; color: blue;"
                (click)="openPopup('Amazon Simple Storage Service')">View Details</span>
            </div>
          </div>
          <div class="row">
            <input type="number" class="form-control" [(ngModel)]="s3Mrr" />
          </div>
        </div>


        <div *ngIf="(showCloudFrontDiscountFields || isAllSelect) && selectedClientName !== 'NHA'">
          <div class="font-weight-bold mt-3 d-flex justify-content-between">
            <div>
              CFRC Discount
            </div>
            <div>
              <!-- data-bs-toggle="modal"
              data-bs-target="#expandmodel"  -->
              <span class="view-details" style="cursor: pointer; color: blue;"
                (click)="openPopup('Amazon CloudFront')">View Details</span>
            </div>
          </div>
          <div class="row">
            <!-- <label>CFRC Discount</label> -->
            <input type="number" class="form-control" [(ngModel)]="cfrcMrr" />
          </div>
        </div>
        <div *ngIf="!['Amazon Elastic Compute Cloud', 'Amazon Relational Database Service'].includes(mrr.serviceName)">
          <!-- Content for non-matching service names -->
          <label>MRR Discount</label>
          <input type="number" class="form-control" [(ngModel)]="mrr.mrrDiscount" />
        </div>
      </div>
      <div *ngIf="(showEC2DiscountFields || isAllSelect) && selectedClientName === 'NHA'">
        <div class="font-weight-bold mt-3 row">EC2</div>
        <div class="border p-3 mb-3">
          <div class="row">
            <label>Instance Discount</label>
            <input type="number" class="form-control" [(ngModel)]="mrrDatasub.instance" />
          </div>
          <div class="row">
            <label>EBS Discount</label>
            <input type="number" class="form-control" [(ngModel)]="mrrDatasub.ebs" />
          </div>
          <div class="row">
            <label>Others Discount</label>
            <input type="number" class="form-control" [(ngModel)]="mrrDatasub.others" />
          </div>
        </div>
      </div>
      <div *ngIf="(showEC2DiscountFields || isAllSelect) && selectedClientName !== 'NHA'">
        <div class="font-weight-bold mt-3 d-flex justify-content-between">
          <div>
            EC2 Discount
          </div>
          <div>
            <span class="view-details" style="cursor: pointer; color: blue;"
              (click)="openPopup('Amazon Elastic Compute Cloud')">View Details</span>
          </div>
        </div>
        <!-- data-bs-toggle="modal"
        data-bs-target="#expandmodel"  -->
        <div class="border p-3 mb-3" style="display: flex;">

          <div class="form-group row" style="margin: 10px;">
            <label>OD </label>
            <input type="number" class="form-control" [(ngModel)]="subEc2.od" />
          </div>
          <div class="form-group row" style="margin: 10px;">
            <label>Spot</label>
            <input type="number" class="form-control" [(ngModel)]="subEc2.spot" />
          </div>
        </div>
      </div>
      <div *ngIf="(showRDSDiscountFields || isAllSelect) && selectedClientName !== 'NHA'">
        <div class="font-weight-bold mt-3 d-flex justify-content-between">
          <div>
            RDS OD Discount
          </div>
          <div>
            <!-- data-bs-toggle="modal"
              data-bs-target="#expandmodel" -->
            <span class="view-details" style="cursor: pointer; color: blue;"
              (click)="openPopup('Amazon Relational Database Service')">View Details</span>
          </div>
        </div>
        <div class="row">
          <input type="number" class="form-control" [(ngModel)]="subRds.od" />
        </div>
      </div>
      <div *ngIf="(showS3DiscountFields || isAllSelect) && selectedClientName !== 'NHA'">
        <div class="font-weight-bold mt-3 d-flex justify-content-between">
          <div>
            S3 Discount
          </div>
          <div>
            <!-- data-bs-toggle="modal"
            data-bs-target="#expandmodel" -->
            <span class="view-details" style="cursor: pointer; color: blue;"
              (click)="openPopup('Amazon Simple Storage Service')">View Details</span>
          </div>
        </div>
        <div class="row">
          <!-- <label>S3 </label> -->
          <input type="number" class="form-control" [(ngModel)]="s3Mrr" />
        </div>
      </div>


      <div *ngIf="(showCloudFrontDiscountFields || isAllSelect) && selectedClientName !== 'NHA'">
        <div class="font-weight-bold mt-3 d-flex justify-content-between">
          <div>
            CFRC Discount
          </div>
          <div>
            <!-- data-bs-toggle="modal"
            data-bs-target="#expandmodel"  -->
            <span class="view-details" style="cursor: pointer; color: blue;"
              (click)="openPopup('Amazon CloudFront')">View Details</span>
          </div>
        </div>
        <div class="row">
          <input type="number" class="form-control" [(ngModel)]="cfrcMrr" />
        </div>
      </div>

      <div class="row">
        <label for="comment">Comment</label>
        <textarea id="comment" [(ngModel)]="mrr.comment" class="form-control"></textarea>
      </div>

      <div class="row">
        <label>MRR Period: </label>
        <input type="text" id="dataRangePicker" [(ngModel)]="dateRange" class="form-control" style="width: 300px"
          placeholder="Select date" />
      </div>

      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="updateEntry()" style="
              background-color: #5864ff;
              color: white;
              text-transform: capitalize;
              margin: right 20px;
            ">
            {{ action == "add" ? "add" : action }}
          </button>
          <button class="btn btn-light" (click)="close(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showPopup" class="table-wrapper">
    <div class="table-container">

      <div class="table-responsive">
        <!-- Ensure headers_pop and serviceData[selectedService] are available before rendering the table -->
        <table class="table table-docs table-hover fixed-header tab_table">
          <!-- Table Header -->
          <thead>
            <tr>
              <th>S.I</th>
              <th *ngFor="let h of headers_pop">{{ h.name }}</th>
            </tr>
          </thead>

          <!-- Table Body - Display Data -->
          <tbody *ngIf="serviceData[selectedService] && serviceData[selectedService].length > 0; else noData">
            <tr *ngFor="let i of serviceData[selectedService]; let j = index">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of headers_pop">
                {{ i[h.id] || '-' }}
              </td>
            </tr>
          </tbody>

          <!-- No Data Found -->
          <ng-template #noData>
            <tbody>
              <tr>
                <td [attr.colspan]="headers_pop.length + 1">
                  <div style="text-align: center">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </ng-template>
        </table>
      </div>
      <div class="modal-footer sticky-footer">
        <button *ngIf="lek !== null && lek !== undefined && lek !== ''" class="btn btn-primary-soft"
          (click)="fetchDetails(service_Name)">
          Next
        </button>
        <button type="button" class="btn btn-success-soft" (click)="closePopup()">Close</button>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }

    .table-wrapper {
      display: flex;
      justify-content: center;
      /* Center horizontally */
      align-items: center;
      /* Center vertically */
      height: 100vh;
      /* Full viewport height */
      position: relative;
    }

    .table-container {
      max-height: 80%;
      /* Set max height for vertical scrolling */
      overflow-y: auto;
      /* Enable vertical scrolling */
      width: 80%;
      /* Adjust as needed for width */
      border: 1px solid #ddd;
      /* Optional border */
      background-color: #fff;
      /* Background for clarity */
      padding: 30px;
      position: relative;
      padding-bottom: 60px;
      display: flex;
      flex-direction: column;
    }



    .sticky-footer {
      position: sticky;
      bottom: 0;
      width: 100%;
      /* Ensure it takes the full width of the table container */
      background-color: #fff;
      /* Adjust background color if needed */
      padding: 10px;
      /* Add padding to match your design */
      /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); Optional: shadow for better visibility */
      z-index: 10;
      /* Ensure it stays on top */
      text-align: right;
      /* Align buttons to the right */
    }

    .table-responsive {
      max-height: 100%;
      /* Make it take full container height */
    }
  </style>
</div>