<div class="card mt-20" style="border: none; position: relative; height: 100%;">
    <h5 class="card-header">Elastic Cache</h5>
    <div class="card-body">
      <div style="display: flex; justify-content: flex-end; align-items: center;">
        <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="addPurchase()">Add
          Purchase Details</button>
      </div>
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of headers">{{ h.name }}</th>
            <th>Update</th>
            <th>Delete</th>
          </tr>
        </thead>
  
        <tbody *ngFor="let i of riData; let j = index"
          [ngStyle]="{'display': riData.length > 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td>{{j + 1}}</td>
            <td *ngFor="let h of headers">
              <span *ngIf="!h.click" [innerHTML]="i[h.id] || '-'"> </span>
              <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || '-'" (click)="callFunction(h.click, i)">
              </span>
  
            </td>
            <td><span (click)="updateTrigger(i)" class="btn btn-light"><i class="fa fa-pencil"></i></span></td>
            <td><span (click)="confirmdeletion(i)" class="btn btn-light"><i class="fa fa-trash"></i></span></td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': riData.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers.length + 3">
              <div style="text-align: center;">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <div style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 999; background-color: rgba(0, 0, 0, 0.1);"
    [ngStyle]="{'display': unsavedRI.length > 0 ? 'block': 'none'}">
    <div
      style="position: relative; margin: 10px; gap: 10px; background-color: white; border-radius: 10px; padding: 75px 20px 20px 20px; box-shadow: 1px 1px 3px 1px grey; display: flex; flex-direction: column; max-height: calc(100% - 20px);">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <h4>Unsaved RI Order</h4>
        <div style="display: flex; justify-content: flex-end; align-items: center;">
          <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="addPurchase()">Add
            Purchase Details</button>
        </div>
      </div>
      <div style="position: absolute; top: 10px; right: 10px; padding: 10px; cursor: pointer;" (click)="unsavedRI = []"
        class="btn btn-light">
        X
      </div>
      <div style="overflow: auto; flex: 1;">
        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th *ngFor="let h of headers">{{ h.name }}</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
  
          <tbody *ngFor="let i of unsavedRI; let j = index"
            [ngStyle]="{'display': unsavedRI.length > 0 ? 'table-row-group' : 'none'}">
            <tr>
              <td>{{j + 1}}</td>
              <td *ngFor="let h of headers">
                <span *ngIf="!h.click" [innerHTML]="i[h.id] || '-'"> </span>
                <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || '-'"
                  (click)="callFunction(h.click, i)">
                </span>
  
              </td>
              <td><span (click)="updateUnsavedRI(i, j)" class="btn btn-light"><i class="fa fa-pencil"></i></span></td>
              <td><span (click)="deleteUnsavedRI(j)" class="btn btn-light"><i class="fa fa-trash"></i></span></td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{'display': unsavedRI.length == 0 ? 'table-row-group' : 'none'}">
            <tr>
              <td [attr.colspan]="headers.length + 3">
                <div style="text-align: center;">No Data Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="display: flex; justify-content: flex-end; align-items: center; padding: 15px;">
        <button class="btn btn-light" (click)="saveDetails()" style="background-color: #5864FF; color: white;">
          Save Details
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="delete" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); backdrop-filter: blur(5px); display: flex; align-items: center; justify-content: center;">
    <div class="card" style="height: 200px; width: 60%; padding: 18px; background: white; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);justify-content: space-between;">
      <h4>Are you sure you want to delete?</h4>
      <div style="text-align: end;">
        <button class="btn btn-danger" style="margin: 0px 4px;" (click)="deleteTrigger(indexdelete)">Delete</button>
        <button class="btn" style="margin: 0px 4px; background-color: rgb(228, 230, 232);" (click)="delete = false">Cancel</button>
      </div>
    </div>
  </div>
  
  <elastic-enableri-update *ngIf="updateRI" [action]="updateAction" [ri]="updateRI" (hideModel)="closeUpdateTrigger($event)">
  </elastic-enableri-update>