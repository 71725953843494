import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';

declare let window: any;
declare let $: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './masterrireport.component.html',
})
export class MasterRiReport implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  // summary: any = [];
  linkedAccounts: any = {};
  accounts: any;
  accountId: any;
  regionId: any;
  currentMessage: any;
  dateRange: any;
  selectedAccountId: any;

  selectedMasterAccount: any;

  headers: any = [
    {
      id: 'mid',
      name: 'Master Account ID',
      filter: true,
    },
    {
      id: 'lid',
      name: 'Linked Account ID',
      filter: true,
    },

    {
      id: 'instanceType',
      name: 'Instance Type',

      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'os',
      name: 'OS',
      // alter: (value: any, param: string) => {
      //   return Number(value[param]).toFixed(2);
      // },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'region',
      name: 'Region',
      // alter: (value: any, param: string) => {
      //   return Number(value[param]).toFixed(2);
      // },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'quantity',
      name: 'Quantity',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'riType',
      name: 'RI Type',
      // alter: (value: any, param: string) => {
      //   return Number(value[param]).toFixed(2);
      // },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'discoutPerc',
      name: 'Discount Percentage',
      // alter: (value: any, param: string) => {
      //   return Number(value[param]).toFixed(2);
      // },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'riHrCost',
      name: 'RI Hour Cost',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'startDate',
      name: 'Start Date',

      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'endDate',
      name: 'End Date',

      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
  ];

  funRef: any = {};
  CBSPortal: boolean = window.location.href.indexOf('cbs.swayam.cloud') > -1;
  masterAccountName: any;
  previousMasterAccountName: any;
  currentIndex: any;
  // CBSPortal: boolean = true;

  constructor(
    public notifier: NotifierService,
    private apiService: APIService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    // this.fetchMasterAccounts();
    this.load();


    this.funRef = {
      loadLids: this.loadLidData,
    };

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };

    let temp = moment(new Date());
    this.dateRange = temp.format('MM/YYYY');

    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm/yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    this.linkedAccounts = JSON.parse(
      localStorage.getItem('accountIdData') || '{}'
    );
    if (Array.isArray(this.linkedAccounts.list)) {
      this.accounts = this.linkedAccounts.list.map(
        (accountObj: any) => accountObj.account
      );
      if (this.linkedAccounts.list && this.linkedAccounts.list.length > 0) {
        this.selectedAccountId = this.linkedAccounts.list[0].accountId;
      }
    } else {
      console.error(
        'linkedAccounts does not have a valid list key or it is not an array.'
      );
    }
    setTimeout(() => {
      $('#linkedAccounts').selectpicker('refresh');
    }, 100);

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      } else if (d.key == 'accountIdData') {
        this.linkedAccounts = d.value;
        this.load();
      }
    });

    console.log(this.linkedAccounts, 'linkedaccounts');
    console.log(this.linkedAccounts['list'][0], 'firdt');
  }

  

  nextToken: any;
  fetching: boolean = true;
  async load() {
    console.log("rimodels",this.rimodels);
    this.nextToken = null;
    this.rimodels = [];
    this.lek = null;
    this.letrack = null;
    await this.fetchMasterAccounts();
    this.updatemasterindex();
  }

  // refresh() {
  //     this.loadLidData();
  // }
  masterAccounts: any;

  async fetchMasterAccounts() {
    this.fetching = true;
    this.notifier.loading(true);
    let data = { action: 'fetchMasterAccountList' };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/prod/msopsusers/billing/fetchmasteraccountlist`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccountIDList;
      setTimeout(async () => {
        $('#masterAccountList').selectpicker();
        $('#masterAccountList').val(this.masterAccounts[0]);
        $('#masterAccountList').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
    this.fetching = false;
  }

  rimodels: any =[];
  lek: any;
  letrack: any;

  updatemasterindex(){
    if (this.letrack == null) { // Check if lektrack is null or undefined
      this.masterAccounts.forEach((account:any) => {
        this.loadLidData(account);
      });
      return;
    }
  }

  async loadLidData(master:any) {
    this.notifier.loading(true);
    let data;

    // let master;
    // master =
    //   $('#masterAccountList').val() === null ||
    //   $('#masterAccountList').val() === undefined
    //     ? this.masterAccounts[0]
    //     : $('#masterAccountList').val();

        console.log("lek",this.lek);
        console.log("lektrack", this.letrack);

    if ((this.letrack === undefined || this.letrack === null)) {
      data = {
        action: 'fetch',
        masterId: master,
      };
    } else {
      data = {
        action: 'fetch',
        masterId: master,
        lek: this.lek,
        lekTrack: this.letrack,
      };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    console.log("mastyer",master);
    let apiURL =
      'https://dev.api.swayam.cloud/v3/admin/billing/master-ri-report';
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log("result",result);
    if (result.hasOwnProperty('status')) {
      if (result.status == '1' || result.s == '1') {
        if (result.riModels && Array.isArray(result.riModels)) {
          this.rimodels = this.rimodels.concat(result.riModels);
          // this.lek = result.lek;
          this.letrack = null;
        }
      } else {
        // If status is not '1', call the method again
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
        this.letrack = null;

      }
    }
    else {
      // If status is not '1', call the method again
      this.rimodels = this.rimodels.concat(result.riModels);
          this.lek = result.lek;
          this.letrack = result.lekTrack;
      await this.loadLidData(master);
    }
    this.notifier.loading(false);
  }


  export() {
    let element = document.createElement('table');
    let total: number = 0;
    
    // Create the table header
    element.innerHTML = `
      <thead>
        <tr>
          <th>Sl. No.</th>
          ${this.headers.map((h:any) => `<th>${h.name}</th>`).join('')}
        </tr>
      </thead>
      <tbody>
        ${this.rimodels.map((item: any, index: number) => {
          let rowValues = this.headers.map((h:any) => {
            let value = h.alter ? h.alter(item, h.id) : item[h.id];
            if (typeof value === 'number') {
              total += value; // Assuming the value is a number to be totaled
            }
            return `<td>${value}</td>`;
          }).join('');
  
          return `
            <tr>
              <td>${index + 1}</td>
              ${rowValues}
            </tr>
          `;
        }).join('')}

      </tbody>
    `;
  
    // Export the constructed table element
    window.exportToExcelElement(
      element,
      `table-data-export-${new Date().toISOString().slice(0, 10)}`,
      'csv'
    );
  }
  


  callFunction(param: any, value: any) {
    this.funRef[param](this, value);
  }

  // export() {
  //   if (this.selectedMasterAccount) {
  //     window.exportToExcelElement(
  //       document.getElementById('linked_data_table'),
  //       `Linked Account - ${this.selectedMasterAccount.masterAccountId}`,
  //       'csv'
  //     );
  //   } else {
  //     window.exportToExcelElement(
  //       document.getElementById('master_accounts_table'),
  //       `Master Accounts`,
  //       'csv'
  //     );
  //   }
  // }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
