import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register/register.component';
import { RICoverageCBSComponenet } from './views/cbs/ricoverage/ricoverage.component';
import { RIPurchasedComponent } from './views/cbs/ripurchased/ripurchased.component';
import { MinfyRIAccountComponent } from './views/cbs/minfyriaccount/minfyriaccount.component';
import { OrganizationsCBSComponent } from './views/cbs/organizations/organizations.component';
import { RIOrderComponent } from './views/cbs/enableri/riorder.component';
import { ViewCFRCComponent } from './views/cbs/cfrc/view/cfrc.view.component';
import { EnableCFRCComponent } from './views/cbs/cfrc/enable/cfrc.enable.component';
import { MasterSummaryComponent } from './views/cbs/mastersummary/mastersummary.component';
import { EnableMasterComponent } from './views/cbs/enablemaster/enablemaster.component';
import { DiscountComponent } from './views/cbs/discount/discount.component';
import { supportPlanComponent } from './views/cbs/supportplan/supportplan.component';
import { OnBoardedMasterComponent } from './views/cbs/onboardedmaster/onboardedmaster.component';
import { RDSRIOrderComponent } from './views/cbs/rdsenableri/rdsriorder.component';
import { CBSDashComponent } from './cbsDash/dash.component';

import { MinfyRiDetailsComponent } from './views/cbs/minfyridetails/minfyridetails.component';
import { BudgetComponent } from './views/cbs/budget/budget.component';
import { HighestBillComponent } from './views/cbs/highestbill/highestbill.component';
import { CostvarianceComponent } from './views/cbs/costvariance/costvariance.component';
import { BudgetsAllComponent } from './views/cbs/budgets-all/budgets-all.component';
import { ErpIntegrationsComponent } from './views/cbs/erp-integrations/erp-integrations.component';
import { MinfyriwrComponent } from './views/cbs/minfyriwr/minfyriwr.component';
import { ErpPlReportComponent } from './views/cbs/erp-pl-report/erp-pl-report.component';
import { PoAlertComponent } from './views/cbs/po-alert/po-alert.component';
import { PnlDashComponent } from './views/cbs/erp-pl-report/pnldashboard/pnldash.component';
import { ConvertionRateComponent } from './views/cbs/erp-pl-report/convertionrate/convertionrate.component';
import { CfrcreportComponent } from './views/cbs/cfrcreport/cfrcreport.component';
import { RiInventoryComponent } from './views/cbs/ri-inventory/ri-inventory.component';
import { SpInventoryComponent } from './views/cbs/sp-inventory/sp-inventory.component';
import { SalesReportComponent } from './views/cbs/sales-report/sales-report.component';

import { MRRDiscountComponent } from './views/cbs/mrr-discount/mrr.component';
// import { Minfyridetailswithsputilizedamount } from './views/cbs/minfyridetailswithsputilizedamount/minfyridetailswithsputilizedamount.component';
import { MarginConsolidation } from './views/cbs/erp-pl-report/margin-consolidation/marginconsolidation.component';
import { MarginDashboard } from './views/cbs/erp-pl-report/margin-dashboard/margin.component';
import { PnlSummaryComponent } from './views/cbs/erp-pl-report/pnlsummary/pnlsummary.component';
import { UnbilledPnlComponent } from './views/cbs/erp-pl-report/unbilled-pnl/unbilled-pl.component';
import { FinanacePnl } from './views/cbs/erp-pl-report/FinanaceYearReport-pnl/FinanaceYearReport-pnl.component';
import { FinanceUnbilledPnl } from './views/cbs/erp-pl-report/FinanceYearReport-unbilledpnl/FinanceYearReport-unbilledpnl.component';
import { CfrcEnableV3 } from './views/cbs/cfrcenable-v3/cfrcenable-v3.component';
import { CfrcEnableV3Update } from './views/cbs/cfrcenable-v3/cfrcupdate-v3/cfrcupdate-v3.component';
import { SingleMasterJob } from './views/cbs/single-master/master-job.component';
import { RDSRIComponent } from './views/cbs/rdsri/rdsri.component';
import { EnableCFRCOrderComponent } from './views/cbs/cfrc/enableorder/cfrc.enable.component';
import { S3Component } from './views/cbs/s3/s3.component';
import { ElasticCacheComponent } from './views/cbs/elastic-cache/elasctic.component';
import { MasterRiReport } from './views/cbs/masterrireport/masterrireport.component';
const cbsRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, title: 'Swayam - Login' },
  {
    path: 'register',
    component: RegisterComponent,
    title: 'Swayam - Register',
  },
  {
    path: 'cbs',
    component: CBSDashComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'ricoverage',
        component: RICoverageCBSComponenet,
        title: 'CBS - RI Coverage',
      },
      {
        path: 'ripurchased',
        component: RIPurchasedComponent,
        title: 'CBS - RI Purchased',
      },
      {
        path: 'minfyri',
        component: MinfyRIAccountComponent,
        title: 'CBS - Minfy RI Accounts',
      },
      {
        path: 'minfyriwr',
        component: MinfyriwrComponent,
        title: 'CBS - Enable WR',
      },
      {
        path: 'organizations',
        component: OrganizationsCBSComponent,
        title: 'CBS - Organizations',
      },
      {
        path: 'riorder',
        component: RIOrderComponent,
        title: 'CBS - RI Order',
      },
      {
        path: 'rds/riorder',
        component: RDSRIOrderComponent,
        title: 'RDS RI Order',
      },
      {
        path: 'elastic-cache',
        component: ElasticCacheComponent,
        title: 'Elastic Cache',
      },
      {
        path: 'rdsri',
        component: RDSRIComponent,
        title: 'RDS RI',
      },
      
      {
        path: 's3',
        component: S3Component,
        title: 'S3',
      },
      {
        path: 'cfrc/view',
        component: ViewCFRCComponent,
        title: 'CBS - CFRC',
      },
      {
        path: 'cfrc/enable',
        component: EnableCFRCComponent,
        title: 'CBS - Enable CFRC',
      },
      {
        path: 'cfrc/enableorder',
        component:  EnableCFRCOrderComponent,
        title: 'CBS - Enable Order CFRC',
      },
     
      {
        path: 'cfrc-report',
        component: CfrcreportComponent,
        title: 'CBS - CFRC Report',
      },
      {
        path: 'pnl-report',
        component: ErpPlReportComponent,
        title: 'CBS - PnL Report',
      },
      {
        path: 'pnl-dashboard',
        component: PnlDashComponent,
        title: 'CBS - PnL Dashobard',
      },
      {
        path: 'pnl-convert',
        component: ConvertionRateComponent,
        title: 'CBS - PnL Convertionrate',
      },
      {
        path: 'ri-inventory',
        component: RiInventoryComponent,
        title: 'CBS - Ri Inventory',
      },
      {
        path: 'sp-inventory',
        component: SpInventoryComponent,
        title: 'CBS - Sp Inventory',
      },
      {
        path: 'mastersummary',
        component: MasterSummaryComponent,
        title: 'CBS - Master Summary',
      },
      {
        path: 'masterrireport',
        component: MasterRiReport,
        title: 'Master RI Report',
      },
      {
        path: 'masterenable',
        component: EnableMasterComponent,
        title: 'CBS - Enable Master',
      },
      {
        path: 'discount',
        component: DiscountComponent,
        title: 'CBS - Discount',
      },
      {
        path: 'enablesupportplan',
        component: supportPlanComponent,
        title: 'CBS - Enable Support Plan',
      },
      {
        path: 'onboardedmaster',
        component: OnBoardedMasterComponent,
        title: 'CBS - Onboarded Masters',
      },
      {
        path: 'minfyridetails',
        component: MinfyRiDetailsComponent,
        title: 'CBS - Minfy RI Details',
      },
      { path: 'budgets', component: BudgetComponent, title: 'CBS - Budgets' },
      {
        path: 'budgets-all',
        component: BudgetsAllComponent,
        title: 'CBS - All Budgets',
      },
      {
        path: 'costvariance',
        component: CostvarianceComponent,
        title: 'CBS - Cost Variance',
      },
      {
        path: 'top10bills',
        component: HighestBillComponent,
        title: 'CBS - Top 10 Bills',
      },
      {
        path: 'erp',
        component: ErpIntegrationsComponent,
        title: 'ERP',
      },
      {
        path: 'po-alert',
        component: PoAlertComponent,
        title: 'PO Alert',
      },
      {
        path: 'sales-report',
        component: SalesReportComponent,
        title: 'Sales Report',
      },
      {
        path: 'mrr-discount',
        component: MRRDiscountComponent,
        title: 'MRR Discount',
      },
      // {
      //   path: 'minfyridetails-part2',
      //   component: Minfyridetailswithsputilizedamount,
      //   title: 'CBS - Minfy RI Details Part 2',
      // },
      {
        path: 'margin-perc-consolidation',
        component: MarginConsolidation,
        title: 'Margin percentage consolidation',
      },
      {
        path: 'margin-dash',
        component: MarginDashboard,
        title: 'Margin Dashboard',
      },
      {
        path: 'pnl-summary',
        component: PnlSummaryComponent,
        title: 'PnL Summary',
      },
      {
        path: 'unbilled-pnl',
        component: UnbilledPnlComponent,
        title: 'Unbilled PnL',
      },
      {
        path: 'finance-pnl',
        component: FinanacePnl,
        title: 'Finanace Pnl',
      },
      {
        path: 'finance-unbilledpnl',
        component: FinanceUnbilledPnl,
        title: 'Finanace Unbilled Pnl',
      },
      {
        path: 'cfrc-enble-v3',
        component: CfrcEnableV3,
        title: 'CFRC Enable',
      },
      {
        path: 'cfrc-edit-v3',
        component: CfrcEnableV3Update,
        title: 'CFRC Enable edit',
      },
      {
        path: 'single-master-job',
        component: SingleMasterJob,
        title: 'Master job run',
      },
    ],
  },
];

// if (current_location == 'cbs.swayam.cloud') {
//   activeRoutes = cbsRoutes;
// } else if (current_location == 'cbsadmin.swayam.cloud') {
//   activeRoutes = cbsAdminRoutes;
// } else if (current_location == 'swayam-pipeline-portal-internal') {
//   activeRoutes = DevelopersRoutes;
// } else {
//   activeRoutes = routes;
// }

@NgModule({
  imports: [RouterModule.forRoot(cbsRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
