import {
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
} from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { Router } from '@angular/router';

declare let $: any;
declare let window: any;
declare let flatpickr: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './cfrc.enable.component.html',
})
export class EnableCFRCOrderComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  riData: any[] = [];
  updateRI: any;
  updateAction: any = 'update';
  type:any;
  dateRange: any;

  accountId: any;
  regionId: any;
  currentMessage: any;
  unsavedRI: any = [];

  headers: any = [
    {
      id: 'regionn',
      name: 'Region',
    },
    {
      id: 'usageType',
      name: 'Usage Type',
    },
    {
      id: 'ppu',
      name: 'Price Per Unit(USD)',
    },
    {
      id: 'discountPercentage',
      name: 'Discount',
    },
    {
      id: 'unit',
      name: 'Unit',
    },


    {
      id: 'description',
      name: 'Description',
    },
    {
      id: 'uuid',
      name:'uuid',
    }

  ];
  bef_headers: any = [
    {
      id: 'regionn',
      name: 'Region',
    },
    {
      id: 'usageType',
      name: 'Usage Type',
    },
    {
      id: 'ppu',
      name: 'Price Per Unit(USD)',
    },
    {
      id: 'discount',
      name: 'Discount',
    },
    {
      id: 'unit',
      name: 'Unit',
    },


    {
      id: 'description',
      name: 'Description',
    },
    {
      id: 'uuid',
      name:'uuid',
    }

  ];

  funRef: any = {};
  deleteRI: any;
  unsavedUpdateId: number = -1;

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {};

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-m-d',
      mode: 'range',
      showMonths: 2,
      defaultDate: this.dateRange ? this.dateRange.split(' to ') : null,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });
  }
  period:any;
  async load() {
    this.notifier.loading(true);

    let data = { accountId: this.accountId, action: 'fetch' };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.riData = result.data;
      if(result.data.length > 0){
        this.period = this.riData[0].startDate + ' to ' + this.riData[0].endDate;
      }
      else{
        this.period = '-';
      }

    } else {
      this.riData = [];
      // console.log("length of array",this.riData.length);
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.period = '-';
    }
    this.notifier.loading(false);
  }

  updateTrigger(id: any) {
    this.updateAction = 'update';
    this.updateRI = id;
  }

  deleteUnsavedRI(id: number) {
    this.unsavedRI.splice(id, 1);
  }

  updateUnsavedRI(id: any, index: number) {
    this.updateAction = 'save';
    this.unsavedUpdateId = index;
    this.updateRI = id;
  }

  closeUpdateTrigger(event: any) {
    // console.log('closeUpdateTrigger', event);
    if (event) {
      // console.log('in event');
      // if (this.updateAction == 'update') {
        this.load();
      // } else {
        
      //   if (this.unsavedUpdateId > -1) {
      //     this.unsavedRI[this.unsavedUpdateId] = event.data[0];
      //     this.unsavedUpdateId = -1;
      //   } else {
      //     if(event.type == 'all'){
      //       // console.log('in all');
      //       this.load();
      //     }
      //     else{
      //       this.unsavedRI.push(event.data[0]);

      //     }
      //   }
      // }
    }

    this.updateRI = null;
  }
  indexdelete:any;
  delete: boolean =false;
  deleteall: boolean = false;

  confirmdeletion(i:any){
    this.delete = true;
    this.indexdelete = i;
  }

  getDeleteButtonLabel(): string {
    return this.riData?.some(item => item.checked) ? 'Delete Selected' : 'Delete All';
  }
  

 async deleteTriggerall(){
  this.deleteall = false;
  let data:any;
  this.notifier.loading(true);

  const checkedRows = this.riData.filter(row => row.checked);

  if (checkedRows.length > 0) {
    // At least one row is checked, use the provided payload
    data = {
      accountId: this.accountId,
      action: 'delete',
      uuids: checkedRows.map(row => row.uuid), // Collect all uuids of checked rows
    };

    console.log('Deleting selected rows:', data);
    // Call your API or perform the deletion using the payload
    // Example: this.deleteSelectedRows(payload);
  }
  else{

      data= {
        accountId: this.accountId,
        action: 'deleteAll',
      };
    }
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3`;
      let result = await this.apiService.postDataPromis(apiURL, data, header);
      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.load();
        this.delete = false;
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
        this.delete = false;
      }
  
      this.notifier.loading(false);
  }
  async deleteTrigger(id: any) {
    this.notifier.loading(true);
    let data: any = {
      accountId: this.accountId,
      action: 'delete',
      uuid: id.uuid,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.load();
      this.delete = false;
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.delete = false;
    }

    this.notifier.loading(false);
  }

  closeDeleteTrigger(event: any) {
    this.deleteRI = null;
  }

  addPurchase() {
    this.updateAction = 'save';
    this.unsavedUpdateId = -1;

    this.updateRI = {
      accountId: this.accountId,
      description: "",
      regionn: [],
      usageType: [],
      unit: "",
      ppu: "",
      discountType: 'percentage',
      discountPercentage:'',
      linkedAccounts: [],
      endDate: moment().startOf('day').format('YYYY-MM-DD'),
      startDate: moment().startOf('day').subtract(1).format('YYYY-MM-DD'),
    };
  }

//   async saveDetails() {
//     if(this.dateRange == '' || this.dateRange == undefined){
//       this.notifier.alert('Info', '', 'Please select date range', 'info', 500);
//       return;
//     }
//     this.notifier.loading(true);
//     const start = this.dateRange.split('to')[0].trim();
//     const end = this.dateRange.split('to')[1].trim();
//     let data: any = {
//       accountId: this.accountId,
//       action: 'add',
//       data: this.unsavedRI.map((ri: any) => {
//         delete ri.id;
//         ri.startDate = start;
//         ri.endDate = end;
//         return ri;
//       }),
//     };
// // console.log(data.data,"data in data for save");
//     let header = {
//       Authorization: localStorage.getItem('t'),
//     };

//     let apiURL: string =
//       'https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3';

//     let result = await this.apiService.postDataPromis(apiURL, data, header);

//     if (result.status == '1' || result.s == '1') {
//       this.notifier.alert('Success', '', result.msg, 'success', 5000);
//       this.unsavedRI = [];
//       this.load();
//     } else {
//       this.notifier.alert('Info', '', result.msg, 'info', 5000);
//     }
//     this.notifier.loading(false);
//   }

  callFunction(param: any, id: any) {
    this.funRef[param](this, id);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
